<template>
    <footer>
        <div class="container">
            <div class="container-content">
                <img class="home-logo" src="@/assets/mensaLogo.svg">
                <div class="container-title">
                    Hrvatska Mensa
                </div>
            </div>
        </div>
        <div class="container-bottom">
            Test
        </div>
    </footer>
</template>

<script>
export default {
    name: 'FooterBar'
}
</script>

<style>
.container {
    background-color: var(--accent-color-1);
    padding: 60px;
    bottom: 0;
    display: flex;
    align-content: center;
    justify-content: center;
}

.container-content {
    width: 1400px;
}

.container-title {
    padding: 30px;
    display: flex;
    font-family: 'Roboto', sans-serif;
    font-size: 60px;
    color: white;
    justify-content: start;
}

.container-bottom {
    background-color: #0B0903;
    height: 80px;
    bottom: 0;
}
</style>



