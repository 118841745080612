<template>
    <div>
      <h1>News Feed</h1>
      <div v-if="articles.length">
        <ul>
          <li v-for="article in articles" :key="article.id">
            <router-link :to="`/news/${article.id}`">{{ article.title }}</router-link>
          </li>
        </ul>
      </div>
      <div v-else>
        <p>No articles available</p>
      </div>
    </div>
  </template>
  
  <script>
  
  export default {
    data() {
      return {
        articles: [],
      };
    },
    async created() {
      const response = await this.$directusService.fetchArticles();
      this.articles = response.data;
    },
  };
  </script>
  