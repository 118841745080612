<template>
    <div class="hero">
        <div class="hero-content">
            <div class="first-line">
                Dobrodošli na službenu stranicu
            </div>
            <div class="second-line">
                Hrvatske Mense
            </div>
            <div class="third-line">
                Ukoliko želite primiti obavijest o idućem testiranju u željenom gradu prijavite se putem weba i saznajte
                prvi
                sve detalje oko testiranja
            </div>
            <div class="button-section">
                <MyButton link="http://localhost:8080/testiranje" type="light" size="large">Testiraj se</MyButton>
                <MyButton type="light" size="large" transparent>Saznaj više</MyButton>
            </div>
        </div>
    </div>
</template>

<script>
import MyButton from "@/components/button/Button.vue"

export default {
    name: 'HeroBlock',
    components: {
        MyButton,
    }
}
</script>

<style>
.hero {
    display: flex;
    width: 100%;
    justify-content: center;
}

.hero-content {
    display: flex;
    padding-top: 200px;
    padding-bottom: 100px;
    width: 1400px;
    flex-direction: column;
    align-items: center;
}

.first-line {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 200;
    font-size: 60px;
    line-height: 100%;
    text-align: center;
    text-transform: uppercase;
    color: var(--white);
}

.second-line {
    font-family: 'Roboto';
    letter-spacing: -5px;
    font-style: normal;
    font-weight: 400;
    font-size: 150px;
    line-height: 92%;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: var(--primary-color);
}

.third-line {
    line-height: 90%;
    font-family: 'Roboto Condensed', sans-serif;
    width: 930px;
    font-size: 30px;
    color: var(--accent-color-3);
}

.button-section {
    padding: 30px;
}
</style>