<template>
    <MyButton v-if="!isAuthenticated" type="light" size="medium" transparent @click="login">Login</MyButton>
    <MyButton v-if="isAuthenticated" type="light" size="medium" transparent @click="logout">Logout</MyButton>
</template>

<script lang="ts">
import keycloakService from '@/auth/KeycloakService';
import { defineComponent } from 'vue';
import MyButton from "@/components/button/Button.vue"

export default defineComponent({

    computed: {
        isAuthenticated() {
            return keycloakService.isAuthenticated();
        }
    },

    name: 'MemberLogin',
    setup() {
        const login = async () => {
            keycloakService.login()
        };
        const logout = async () => {
            keycloakService.logout()
        }
        return {
            login, logout
        };
    },
    components: {
        MyButton,
    }
});
</script>