import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MyButton = _resolveComponent("MyButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.isAuthenticated)
      ? (_openBlock(), _createBlock(_component_MyButton, {
          key: 0,
          type: "light",
          size: "medium",
          transparent: "",
          onClick: _ctx.login
        }, {
          default: _withCtx(() => [
            _createTextVNode("Login")
          ]),
          _: 1
        }, 8, ["onClick"]))
      : _createCommentVNode("", true),
    (_ctx.isAuthenticated)
      ? (_openBlock(), _createBlock(_component_MyButton, {
          key: 1,
          type: "light",
          size: "medium",
          transparent: "",
          onClick: _ctx.logout
        }, {
          default: _withCtx(() => [
            _createTextVNode("Logout")
          ]),
          _: 1
        }, 8, ["onClick"]))
      : _createCommentVNode("", true)
  ], 64))
}