<template>
    <div class="news">
        <div class="news-content">
            <div class="news-title">
                Vijesti
            </div>
            <div class="news-box">
                <div class="news-list">



                    <div v-for="article in articles" :key="article.id" class="item nw-block">
                        <div class="item nw-image">
                            <DynamicImage :imageUrl="getImgUrl(article.image)" :imageStyle="articleImgStyle" />
                        </div>
                        <div class="item ev-title" v-html="article.title"></div>
                        <div class="item-type">
                            <div class="circle" v-html="getCircleUnicode(article.type)"></div>
                            <div class="item nw-type" v-html="article.type"></div>
                        </div>
                    </div>

                    

                </div>
                <!--<div class="nw-col main">
                    <div class="nw-block item">
                        <div class="item-type">
                            <div class="content-type" v-html="getCircleUnicode(article1.type)"></div>
                            <div class="item nw-type" v-html="article1.type"></div>
                        </div>
                        <router-link :to="`/news/${article1.id}`">
                            <div class="item nw-title" v-html="article1.title"></div>
                            <div class="item nw-image">
                                <DynamicImage :imageUrl="getImgUrl(article1.image)" :imageStyle="eventImgStyle" />
                            </div>
                        </router-link>
                    </div>
                </div>
                <div class="nw-col side">
                    <div class="side first">
                        <div class="nw-block item2"
                            v-bind:style="[article5.image != undefined ? { 'background-image': 'url(' + getImgUrl(article2.image) + ')', 'background-size': 'cover', 'background-position': 'center' } : { 'background': '#ffffff' }]">
                            <div class="item-content">
                                <div class="content-type">
                                    <div class="circle2" v-html="getCircleUnicode(article2.type)"></div>
                                    <div class="item2 nw-type" v-html="article2.type"></div>
                                </div>
                                <div class="content-title">
                                    <div class="item2 nw-title" v-html="article2.title"></div>
                                </div>
                            </div>
                        </div>
                        <div class="nw-block item3"
                            v-bind:style="[article5.image != undefined ? { 'background-image': 'url(' + getImgUrl(article3.image) + ')', 'background-size': 'cover', 'background-position': 'center' } : { 'background': '#ffffff' }]">
                            <div class="item-content">
                                <div class="content-type">
                                    <div class="circle2" v-html="getCircleUnicode(article3.type)"></div>
                                    <div class="item3 nw-type" v-html="article3.type"></div>
                                </div>
                                <div class="content-title">
                                    <div class="item3 nw-title" v-html="article3.title"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="side second">
                        <div class="nw-block item4"
                            v-bind:style="[article5.image != undefined ? { 'background-image': 'url(' + getImgUrl(article4.image) + ')', 'background-size': 'cover', 'background-position': 'center' } : { 'background': '#ffffff' }]">
                            <div class="item-content">
                                <div class="content-type">
                                    <div class="circle2" v-html="getCircleUnicode(article4.type)"></div>
                                    <div class="item4 nw-type" v-html="article4.type"></div>
                                </div>
                                <div class="content-title">
                                    <div class="item4 nw-title" v-html="article4.title"></div>
                                </div>
                            </div>
                        </div>
                        <div class="nw-block item5"
                            v-bind:style="[article5.image != undefined ? { 'background-image': 'url(' + getImgUrl(article5.image) + ')', 'background-size': 'cover', 'background-position': 'center' } : { 'background': '#ffffff' }]">
                            <div class="item-content">
                                <div class="content-type">
                                    <div class="circle2" v-html="getCircleUnicode(article5.type)"></div>
                                    <div class="item5 nw-type" v-html="article5.type"></div>
                                </div>
                                <div class="content-title">
                                    <div class="item5 nw-title" v-html="article5.title"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="news-button">
                <MyButton type="light" size="large" transparent @click="navigateToLink">Pogledaj sve
                    vijesti</MyButton>
            </div>
        </div>
    </div>
</template>

<script>
import MyButton from "@/components/button/Button.vue";
import DynamicImage from "../util/DynamicImage.vue";

export default {
    name: 'NewsBlock',
    components: {
        MyButton,
    },
    data() {
        return {
            articles: [],
            url: '',
            articleImgStyle: {
            }
        };
    },
    created() {
        this.fetchData();
    },
    computed: {
        groupedArticles() {
            let chunkSize = 4;

            return this.articles.reduce((resultArray, item, index) => {
                const chunkIdex = Math.floor(index / chunkSize);

                if (!resultArray[chunkIdex]) {
                    resultArray[chunkIdex] = [];
                }

                resultArray[chunkIdex].push(item);

                return resultArray;
            }, []);
        }
    },
    methods: {
        async fetchData() {
            try {
                const response = await this.$directusService.fetchArticles("?sort=-date_published&limit=8");
                if (response && response.data) {
                    this.articles = response.data.slice(0, 8);
                }
                console.log(this.articles);
                this.url = this.$directusService.fetchUrl();
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },

        getImgUrl(imageId) {
            return String(this.url) + "/assets/" + String(imageId);
        },

        getCircleUnicode(type) {
            switch (type) {
                case 'News':
                    return '&#x1F535;'; // 🔵
                case 'Project':
                    return '&#x1F7E1;'; // 🟡
                case 'Event':
                    return '&#x1F534;'; // 🔴
                case 'Sponsorship':
                    return '&#x1F7E3;'; // 🟣
                case 'Testing':
                    return '&#x1F7E2;'; // 🟢
                default:
                    return '';
            }
        },
    }
}
</script>

<style>
a {
    text-decoration: none;
    color: #FFFFFF;
}

a :hover {
    color: #DDDDDD;
}

.news {
    font-family: 'Roboto';
    font-style: normal;
    text-align: left;
    color: #FFFFFF;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding-inline: 15px;
}

.news-content {
    width: 100%;
    max-width: 1400px;
}

.news-title {
    padding: 30px 30px 15px 30px;
    display: flex;
    line-height: 90%;
    font-family: 'Roboto', sans-serif;
    font-size: 40px;
    color: #FFC700;
}

.news-box {
    display: flex;
    justify-content: space-between;
    max-width: 1400px;
    /* padding: 15px 0px 30px; */
    gap: 10px;
    text-align: left;
}

.news-list {
    display: flex;
    height: 500px;
    width: 100%;
    /* overflow: hidden; */
    gap: 10px;
}

.item {
    width: 100%;
}

.nw-image {
    height: 170px;
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.5);
}


/*
.nw-col {
    width: 100%;
    display: flex;
}

.nw-col .side {
    flex-direction: row;
    gap: 8px;
    height: 100%;
}

.side {
    display: flex;
    flex-direction: column;
    gap: 8px 0px;
}

.nw-block {
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
}

.item-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.6) 15%, rgba(0, 0, 0, 0.3) 30%, rgba(0, 0, 0, 0) 40%);
    justify-content: end;
}

.content-title {
    padding: 10px 20px 20px 20px;
}

.side .content-title {
    padding: 7px 15px 15px 15px;
}

.content-type {
    display: flex;
    flex-direction: row;
    padding: 20px 20px 0px;
    gap: 8px;
}

.side .content-type {
    padding: 15px 15px 0px;
    gap: 5px;
}

.nw-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 100%;
    text-align: left;
    color: #FFFFFF;
    
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

.nw-col .side .nw-title {
    font-size: 20px;
}

.nw-col .side .nw-type {
    font-size: 15px;
}

.circle {
    font-size: 15px;
    line-height: 120%;
}

.circle2 {
    font-size: 10px;
    line-height: 120%;
}

.nw-type {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 200;
    font-size: 20px;
    line-height: 100%;
    text-align: left;
    color: #FFFFFF;
}*/



.news-button {
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
}
</style>