<template>
    <button class="my-button" :class="[
        type && 'my-button-type--' + type,
        size && 'my-button-size--' + size,
        // color && 'my-button-color--' + color,
        {
            'my-button--transparent': transparent,
            'is-loading': loading
        }
    ]" :type="nativeType" @click="navigateToLink">
        <span class="my-button-icon" v-if="loading">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" class="loading-icon loading-wipe">
                <g>
                    <circle cx="25" cy="25" r="20" class="loading-icon-bg-path"></circle>
                    <circle cx="25" cy="25" r="20" class="loading-icon-active-path"></circle>
                </g>
            </svg>
        </span>
        <span>
            <slot></slot>
        </span>
    </button>
</template>

<script>
export default {
    name: 'MyButton',
    props: {
        type: {
            type: String,
            default: 'light'
        }, // plain, full
        // color: {
        //     type: String,
        //     default: 'light'
        // }, // dark, light
        size: String, // small, medium, large
        transparent: Boolean,
        loading: Boolean,
        nativeType: {
            type: String,
            default: 'button'
        }
    },
    methods: {
    navigateToLink() {
      window.location.href = this.link;
    }
  }
}
</script>