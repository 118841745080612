<template>
    <div class="statistics">
        <div class="statistics-content">
            <div class="statistics-title">
                Statistics
            </div>
            <div class="statistics-box">
                <MyButton type="dark" size="large" transparent>Test</MyButton>
                <MyButton type="dark" size="small">Test</MyButton>
                <MyButton type="light" size="medium" transparent>Test</MyButton>
                <MyButton type="light" size="large">Test</MyButton>
            </div>
        </div>
    </div>
</template>

<script>
import MyButton from "@/components/button/Button.vue"

export default {
    name: 'StatisticsBlock',
    components: {
        MyButton,
    }
}
</script>

<style>
.statistics {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: var(--accent-color-2);
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
}

.statistics-content {
    width: 1400px;
    padding: 13px;
}

.statistics-title {
    padding-left: 50px;
    display: flex;
    line-height: 90%;
    font-family: 'Roboto', sans-serif;
    font-size: 40px;
    color: white;
}

.statistics-box {
    height: 160px;
}
</style>