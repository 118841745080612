import { useFetch } from '@/utility/fetch';

class DirectusService {

    private headers = {
        'Authorization': 'Bearer RftRJRerWso5tT1O2rE7DqAcFVMtz-Eq',
    };

    private url = 'https://directus.mensa.hr';

    async fetchArticles(query?: string): Promise<undefined> {
        if (query == null) {
            query = "";
        };
        return await useFetch(this.url + '/items/articles' + query, this.headers);
    }

    async fetchArticleById(id?: number): Promise<undefined> {

        return await useFetch(this.url + '/items/articles/' + id, this.headers);
    }

    async fetchEvents(query?: string): Promise<undefined> {
        if (query == null) {
            query = "";
        };
        return await useFetch(this.url + '/items/events' + query, this.headers);
    }

    async fetchEventById(id?: number): Promise<undefined> {
        
        return await useFetch(this.url + '/items/events/' + id, this.headers);
    }

    async fetchSponsors(query?: string): Promise<undefined> {
        if (query == null) {
            query = "";
        };
        return await useFetch(this.url + '/items/sponsors' + query, this.headers);
    }

    async fetchContent (myContent?: string, query?: string): Promise<undefined> {
        if (query == null) {
            query = "";
        };
        return await useFetch(this.url + '/items/' + myContent + query, this.headers);
    }

    fetchUrl() {
        return this.url;
    }

    init() {
        // Add any initialization logic here if needed
        return Promise.resolve();
      }
}
const directusService = new DirectusService;
export default directusService;