import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import keycloakService from "@/auth/KeycloakService";
import NewsFeed from "@/components/articles/NewsFeed.vue";
import NewsArticle from "@/components/articles/NewsArticle.vue";
import EventFeed from "@/components/articles/EventFeed.vue";
import EventArticle from "@/components/articles/EventArticle.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: '/news',
    name: 'NewsFeed',
    component: NewsFeed,
  },
  {
    path: '/news/:id',
    name: 'NewsArticle',
    component: NewsArticle,
    props: true,
  },
  {
    path: '/event',
    name: 'EventFeed',
    component: EventFeed,
  },
  {
    path: '/event/:id',
    name: 'EventArticle',
    component: EventArticle,
    props: true,
  },
  {
    path: "/kontakt",
    name: "kontakt",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/KontaktView.vue"),
  },
  {
    path: "/omensi",
    name: "omensi",
    component: () =>
      import(/* webpackChunkName: "omensi" */ "../views/OMensi/OMensiView.vue"),
  },
  {
    path: "/testiranje",
    name: "testiranje",
    component: () =>
      import("../views/TestiranjeView.vue"),
  },
  {
    path: "/webshop",
    name: "webshop",
    component: () =>
      import("../views/WebshopView.vue"),
  },
  {
    path: "/internidio",
    name: "internidio",
    meta: {
      requiresAuth: true
    },
    component: () =>
      import("../views/private/InterniDio.vue"),
  },
  {
    path: "/profil",
    name: "profil",
    meta: {
      requiresAuth: true
    },
    component: () =>
      import("../views/private/ProfileView.vue"),
  },
  {
    path: "/statut",
    name: "statut",
    component: () =>
      import("../views/OMensi/StatutView.vue"),
  },
  {
    path: "/duznosnici",
    name: "duznosnici",
    component: () =>
      import("../views/OMensi/DuznosniciView.vue"),
  },
  {
    path: "/mensaintl",
    name: "mensaintl",
    component: () =>
      import("../views/OMensi/MensaIntlView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth && !keycloakService.isAuthenticated()) {
    console.log(to.fullPath)
    keycloakService.login(to.fullPath.slice(1, 0))
  } else {
    next();
  }
});


export default router;
