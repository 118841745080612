import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "app" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_top_bar = _resolveComponent("top-bar")!
  const _component_navigator_bar = _resolveComponent("navigator-bar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_footer_bar = _resolveComponent("footer-bar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_top_bar),
    _createVNode(_component_navigator_bar),
    _createVNode(_component_router_view),
    _createVNode(_component_footer_bar)
  ]))
}