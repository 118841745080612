<template>
    <div v-if="article">
        <h1>{{ article.title }}</h1>
        <p>{{ article.content }}</p>
    </div>
    <div v-else>
        <p>Loading...</p>
    </div>
</template>

<script>
export default {
  props: ['id'],
  data() {
    return {
      article: null,
    };
  },
  async created() {
    const response = await this.$directusService.fetchArticles(`/${this.id}`);
    this.article = response.data;
  },
};
</script>

