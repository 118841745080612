import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "hp-container" }
const _hoisted_2 = { class: "hp-page" }
const _hoisted_3 = { class: "hp-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeroBlock = _resolveComponent("HeroBlock")!
  const _component_SponsorsBlock = _resolveComponent("SponsorsBlock")!
  const _component_NewsBlock = _resolveComponent("NewsBlock")!
  const _component_EventsBlock = _resolveComponent("EventsBlock")!
  const _component_StatisticsBlock = _resolveComponent("StatisticsBlock")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_HeroBlock),
        _createVNode(_component_SponsorsBlock),
        _createVNode(_component_NewsBlock),
        _createVNode(_component_EventsBlock),
        _createVNode(_component_StatisticsBlock)
      ])
    ])
  ]))
}