import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import keycloakService from "./auth/KeycloakService";
import directusService from "@/directus/DirectusService";

// keycloakService.init().then(() => {
//   const app = createApp(App)
//   app.use(store)
//   app.use(router)
//   app.mount("#app")
// }).catch(error => {
//   console.error("Keycloak initialization failed:", error);
// });

Promise.all([keycloakService.init(), directusService.init()])
  .then(() => {
    const app = createApp(App);

    app.config.globalProperties.$directusService = directusService;

    app.use(store);
    app.use(router);
    app.mount('#app');
  })
  .catch(error => {
    console.error("Initialization failed:", error);
  });
