<template>
    <div class="top-bar">
        <div class="icons">
            <div class="language-icons">
                <a href="https://facebook.com" class="external-link">
                    <img src="@/assets/languageIcons/croatian.svg" alt="croatian icon" class="external-link-icon"
                        width="15" height="15">
                </a>
                <a href="https://twitter.com" class="external-link">
                    <img src="@/assets/languageIcons/english.svg" alt="english icon" class="external-link-icon"
                        width="15" height="15">
                </a>
            </div>
            <div class="social-icons">
                <a href="https://facebook.com" class="external-link">
                    <img src="@/assets/socialIcons/facebook.svg" alt="facebook icon" class="external-link-icon"
                        width="15" height="15">
                </a>
                <a href="https://twitter.com" class="external-link">
                    <img src="@/assets/socialIcons/x.svg" alt="twitter icon" class="external-link-icon" width="15"
                        height="15">
                </a>
                <a href="https://youtube.com" class="external-link">
                    <img src="@/assets/socialIcons/youtube.svg" alt="youtube icon" class="external-link-icon" width="15"
                        height="15">
                </a>
                <a href="https://linkedin.com" class="external-link">
                    <img src="@/assets/socialIcons/linkedin.svg" alt="linkedin icon" class="external-link-icon"
                        width="15" height="15">
                </a>
                <a href="https://instagram.com" class="external-link">
                    <img src="@/assets/socialIcons/instagram.svg" alt="instagram icon" class="external-link-icon"
                        width="15" height="15">
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TopBar'
}
</script>

<style>
.top-bar {
    height: 24px;
    padding: 8px;
    display: flex;
    justify-content: center;
}

.icons {
    width: 1600px;
    display: flex;
    justify-content: end;
}

.language-icons {
    padding: 15px;
    display: flex;
    align-items: center;
    gap: 6px;

}

.social-icons {
    padding: 15px;
    display: flex;
    align-items: center;
    gap: 6px;
}

.external-link {
    display: flex;
    align-items: center;
}
</style>
